window.$ = require('jquery');
window.jQuery = window.$;

$(function () {
    // If window.loggedIn if false and the URL has a hash parameter sso=1 (#sso=1 or #<something>&sso=1), show the login modal

    function removeHash() {
        // If the user is logged in and the URL has a hash parameter sso=1, remove the hash parameter
        window.location.hash = window.location.hash.replace(/&?sso=1/, '');

        // Remove the hash completely from the URL to clean up the address bar
        if (window.location.hash === '#' || window.location.hash === '') {
            history.pushState("", document.title, window.location.pathname + window.location.search);
        }
    }

    // Need to wait for window.loggedIn to be set before checking login status
    const checkLoginAndHash = setInterval(() => {
        if (typeof window.loggedIn !== 'undefined') {
            clearInterval(checkLoginAndHash);

            // Check if URL contains sso=1 parameter
            if (window.location.hash.indexOf('sso=1') > -1) {
                // Show login modal if user is not logged in
                if (!window.loggedIn) {
                    $('.modal-shadow').addClass('modal-shadow--visible');
                    $('.login-modal').addClass('login-modal--visible');
                    $('body').addClass('login-modal-visible');
                }
                removeHash();
            }
        }
    }, 100);

    $('body').on('click', '.js-show-login-modal', function (event) {
        if (window.ssoRedirectUrl) {
            window.location.href = window.ssoRedirectUrl;
            return;
        }

        $('.modal-shadow').addClass('modal-shadow--visible');
        $('.login-modal').addClass('login-modal--visible');
        $('body').addClass('login-modal-visible');
    });

    $('.js-close-login-modal').on('click', function (event) {
        $('.modal-shadow').removeClass('modal-shadow--visible');
        $('.login-modal').removeClass('login-modal--visible');
        $('body').removeClass('login-modal-visible');
    });
});