<script>
    import Fuse from 'fuse.js';

	export default {
		name: 'expanding-search',

		components: {
		},

		props: ['userData'],

		data: function() {
			return {
                showField: false,
				terms: '',
                initialTerms: '',
                suggestions: [],
                workSearchSuggestionsUrl: null,
                contactSearchSuggestionsUrl: null,
                pageSearchSuggestionsUrl: null,
                searchResultPageUrl: null,
                debounceTimer: null,
                fuse: null,
                selectedSuggestionIndex: null,
                selectedSuggestionString: null,
			};
		},

        created: function() {
            this.workSearchSuggestionsUrl    = window.workSearchSuggestionsUrl;
            this.contactSearchSuggestionsUrl = window.contactSearchSuggestionsUrl;
            this.pageSearchSuggestionsUrl    = window.pageSearchSuggestionsUrl;
            this.searchResultPageUrl         = window.searchResultPageUrl;

            const urlParams = new URLSearchParams(window.location.search);
            const terms = urlParams.get('terms');
            if (terms && window.location.pathname === '/sokresultat/') {
                this.initialTerms = terms;
                this.terms = terms;
            }

            // Close search field when clicking outside of it
            document.addEventListener('click', (event) => {
                if (!this.$el.contains(event.target)) {
                    this.showField = false;
                }
            });
        },

		watch: {
			terms: function(newValue, oldValue) {
                if (newValue.length >= 2 && newValue === this.selectedSuggestionString) {
                    // Do nothing
                } else if (newValue.length >= 2 && newValue !== this.initialTerms) {
                    this.initialTerms = '';
                    this.debouncedFetchSuggestions();
                } else {
                    this.suggestions = [];
                }
			},
		},

        computed: {
            cartButtonIsVisible: function() {
                console.log(this.userData && this.userData.cart && this.userData.cart.items.length > 0)
                return this.userData && this.userData.cart && this.userData.cart.items.length > 0;
            },
        },

		methods: {
            debouncedFetchSuggestions: function() {
                clearTimeout(this.debounceTimer);
                this.debounceTimer = setTimeout(() => {
                    this.fetchSuggestions();
                }, 50);
            },
            selectSuggestion: function(suggestion) {
                this.initialTerms = suggestion;
                this.terms = suggestion;
                this.suggestions = [];
                this.search();
            },
            fetchSuggestions: function() {
                let startTime = new Date();

                // We need this to work if any of the URLs are missing (which they are if searching is disabled for a specific type)
                let workRequest = this.workSearchSuggestionsUrl ? this.$http.get(this.workSearchSuggestionsUrl, {params: {s: this.terms}}) : Promise.resolve({body: {data: {items: []}}});
                let contactRequest = this.contactSearchSuggestionsUrl ? this.$http.get(this.contactSearchSuggestionsUrl, {params: {s: this.terms}}) : Promise.resolve({body: {data: {items: []}}});
                let pageRequest = this.pageSearchSuggestionsUrl ? this.$http.get(this.pageSearchSuggestionsUrl, {params: {term: this.terms}}) : Promise.resolve({body: []});

                Promise.all([workRequest, contactRequest, pageRequest]).then(responses => {
                    let workSuggestions = responses[0].body.data.items ? responses[0].body.data.items : [];
                    let contactSuggestions = responses[1].body.data.items ? responses[1].body.data.items : [];
                    let pageSuggestions = responses[2].body ? responses[2].body : [];

                    let allSuggestions = [...workSuggestions, ...contactSuggestions, ...pageSuggestions];
                    let suggestions = [...new Set(allSuggestions)];

                    let options = {
                        includeScore: true,
                        shouldSort: true,
                        threshold: 0.3,
                        location: 0,
                        distance: 100,
                        maxPatternLength: 32,
                        minMatchCharLength: 1,
                        includeMatches: true,
                    };

                    let fuse = new Fuse(suggestions, options);

                    let matches = fuse.search(this.terms);

                    // console.log(matches)

                    this.suggestions = matches.map(match => {
                        let matchString = match.item;
                        let matchStringWithMatchingCharsHighlighted = null;
                        // console.log(match.matches)

                        let length = 0;
                        let longestMatch = null

                        match.matches.forEach(match => {
                            match.indices.forEach((matchPositions) => {
                                let start = matchPositions[0];
                                let end = matchPositions[1];

                                if (end - start > length) {
                                    length = end - start;
                                    longestMatch = matchPositions;
                                }
                            });
                        });

                        let start = longestMatch[0]
                        let end = longestMatch[1]

                        matchStringWithMatchingCharsHighlighted = matchString.substring(0, start) + '<strong>' + matchString.substring(start, end + 1) + '</strong>' + matchString.substring(end + 1);

                        return {string: matchString, highlightedString: matchStringWithMatchingCharsHighlighted};
                    }).slice(0, 10);

                    let endTime = new Date();
                    let timeDiff = endTime - startTime;

                    console.debug('Time spent: ' + timeDiff + ' ms');
                }).catch(error => {
                    console.error('Error fetching suggestions:', error);
                    this.suggestions = [];
                });
			},
            search: function() {
                // Direct browser to search results page
                this.suggestions = [];

                let terms = this.terms.trim();

                if (terms.length === 0) {
                    // Do nothing if search field is empty
                    return;
                }

                if (this.terms === this.selectedSuggestionString) {
                    // User is searching for a suggestion, add quotes to make sure we find exact matches
                    terms = '"' + terms + '"';
                }

                window.location.href = this.searchResultPageUrl + '?terms=' + encodeURIComponent(terms);
            },
            selectText() {
                this.$refs.searchInput.select();
            },
            showAndFocus() {
                if (this.showField) {
                    this.showField = false;
                } else {
                    this.showField = true;
                    this.$nextTick(() => {
                        this.$refs.searchInput.focus();
                    });
                }
            },
            selectDown() {
                if (this.suggestions.length > 0) {
                    if (this.selectedSuggestionIndex === null) {
                        this.selectedSuggestionIndex = 0;
                    } else {
                        this.selectedSuggestionIndex = (this.selectedSuggestionIndex + 1) % this.suggestions.length;
                    }
                    this.selectedSuggestionString = this.suggestions[this.selectedSuggestionIndex].string;
                    this.terms = this.selectedSuggestionString;

                    this.$nextTick(() => {
                        document.getElementById('suggestion-' + this.selectedSuggestionIndex).scrollIntoView({behavior: 'smooth', block: 'end'});
                    });
                }
            },
            selectUp() {
                if (this.suggestions.length > 0) {
                    if (this.selectedSuggestionIndex === null) {
                        this.selectedSuggestionIndex = this.suggestions.length - 1;
                    } else {
                        this.selectedSuggestionIndex = (this.selectedSuggestionIndex - 1 + this.suggestions.length) % this.suggestions.length;
                    }
                    this.selectedSuggestionString = this.suggestions[this.selectedSuggestionIndex].string;
                    this.terms = this.selectedSuggestionString;

                    this.$nextTick(() => {
                        document.getElementById('suggestion-' + this.selectedSuggestionIndex).scrollIntoView({behavior: 'smooth', block: 'start'});
                    });
                }
            },
		}
	}
</script>

<template>
    <div v-cloak>
        <button class="expanding-search-button" :class="{'expanding-search-button--cart-visible': cartButtonIsVisible}" @click="showAndFocus">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26">
                <path fill="currentColor" fill-rule="evenodd" d="M2.618 11.117a8.5 8.5 0 0 1 16.998 0 8.455 8.455 0 0 1-1.831 5.26l-1.408 1.407a8.455 8.455 0 0 1-5.26 1.832 8.5 8.5 0 0 1-8.5-8.5m22.705 10.93-4.88-4.88a11.062 11.062 0 0 0 1.792-6.05C22.234 4.978 17.257 0 11.117 0 4.977 0 0 4.977 0 11.117c0 6.14 4.977 11.117 11.117 11.117 2.232 0 4.308-.66 6.05-1.793l4.88 4.88a2.316 2.316 0 0 0 3.275-3.275Z" clip-rule="evenodd" />
            </svg>
        </button>

        <div :class="{'expanding-search': true, 'show': showField}">
            <input
                class="expanding-search__input" ref="searchInput" id="expandingSearch" type="text" placeholder="Sök här!"
                v-model="terms"
                v-on:keyup.enter="search"
                @focus="selectText"
                v-on:keyup.down="selectDown"
                v-on:keyup.up="selectUp"
                v-on:keyup.esc="showField = false"
            >
            <ul class="expanding-search__suggestions" v-show="suggestions.length > 0">
                <!-- Sökresultat -->
                <li v-for="(suggestion, index) in suggestions"
                    :class="{'expanding-search__suggestion': true, 'highlight': index === selectedSuggestionIndex}"
                    :id="'suggestion-' + index"
                    v-on:click="selectSuggestion(suggestion.string)"
                >
                    <span v-html="suggestion.highlightedString"></span>
                </li>

                <!-- Grupperat -->
                <!-- <li class="expanding-search__suggestion-grouped">
                    <h4>Artiklar</h4>
                    <ul>
                        <li class="expanding-search__suggestion article-icon">Apples</li>
                        <li class="expanding-search__suggestion article-icon">Banana</li>
                        <li class="expanding-search__suggestion article-icon">Cherry</li>
                    </ul>
                </li> -->

                <!-- Grupperat -->
                <!-- <li class="expanding-search__suggestion-grouped">
                    <h4>Böcker</h4>
                    <ul>
                        <li class="expanding-search__suggestion book-icon">Apples</li>
                        <li class="expanding-search__suggestion book-icon">Banana</li>
                        <li class="expanding-search__suggestion book-icon">Cherry</li>
                    </ul>
                </li> -->

                <!-- tidigare sökningar -->
                <!-- <li class="expanding-search__previous-searches">
                    <div>
                        <h4>Sökhistorik</h4>
                        <button class="expanding-search__clear-button">Rensa</button>
                    </div>
                    <ul class="expanding-search__previous-list">
                        <li class="expanding-search__previous-item">Apple</li>
                        <li class="expanding-search__previous-item">Banana</li>
                        <li class="expanding-search__previous-item">Cherry</li>
                    </ul>
                </li> -->
                <li class="expanding-search__button"><button @click="search">Visa alla resultat för ”{{ terms }}”</button></li>
            </ul>
        </div>
    </div>
</template>