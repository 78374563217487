import Vue from 'vue';
import VueResource from 'vue-resource';
window.axios = require('axios');
import qs from 'qs';
window.qs = qs;


import ExpandingSearch from './vue-components/search/expanding-search.vue';
import SearchBooks from './vue-components/search/search-books.vue';
import SearchContacts from './vue-components/search/search-contacts.vue';
import AjaxForm from './vue-components/form/ajax-form.vue';
import LoginContainer from './vue-components/common/login-container.vue';
import ProductionSelect from './vue-components/common/production-select.vue';
import Minicart from './vue-components/common/minicart.vue';
import Checkout from './vue-components/common/checkout.vue';
import Returns from './vue-components/common/returns.vue';
import AccountButton from './vue-components/common/account-button.vue';
import WorkProductBox from './vue-components/common/work-product-box.vue';
import NewsletterSignup from './vue-components/common/newsletter-signup.vue';
import NewsletterSignupHeadless from './vue-components/common/newsletter-signup-headless.vue';
import ReadingCopySelect from './vue-components/common/reading-copy-select.vue';
import InvoiceCopy from './vue-components/accounts-receivable/invoice-copy.vue';
import AccountHistory from './vue-components/accounts-receivable/account-history.vue';
import PublisherBar from './vue-components/publisher-bar/publisher-bar.vue';

Vue.use(VueResource);

const EventBus = new Vue();

Object.defineProperties(Vue.prototype, {
	$bus: {
		get: function() {
			return EventBus
		}
	}
});

var csrfToken = false;

Vue.http.interceptors.push(function(request) {

	if ( csrfToken )
	{
		request.headers.set('X-CSRFToken', csrfToken);
	}

	return function(response) {

		csrfToken = response.headers.get('x-csrftoken')
	}

});

module.exports = function() {
	Vue.directive('focus', {
		inserted: function(el) {
			el.focus();
		}
	});

	let app = new Vue({
		el: '#vue-app',

		components: {
            ExpandingSearch,
			SearchBooks,
			SearchContacts,
			LoginContainer,
			ProductionSelect,
			AjaxForm,
			Minicart,
			Checkout,
			Returns,
			AccountButton,
			WorkProductBox,
			NewsletterSignup,
			NewsletterSignupHeadless,
			ReadingCopySelect,
			InvoiceCopy,
			AccountHistory,
			PublisherBar
		},

		data: function() {
			return {
				userData: null,
				themeName: '',
			}
		},

		methods: {
			isLoggedIn: function() {
				return this.userData ? true : false
			},

			reload: function() {
				window.location.reload();
			},

			requestStorageAccess: function (granted, rejected) {
				if ( ! granted )
				{
					granted = function () {}
				}

				if ( ! rejected )
				{pid = this.getCookie("servicegroupid")
					rejected = function () {}
				}

				if ( document && document.requestStorageAccess )
				{
					var p = document.requestStorageAccess();
				}
			},

			buttonClick: function () {
				this.requestStorageAccess(function () {
					this.checkLogin()
				})
			},

			checkLogin: function () {
				var data = {
					credentials: true,
					withCredentials: true,
					headers: {
						'x-publisher': '' + window.publisher
					}
				};

				this.$http.get(window.pressadminBaseUrl + '/user/', data).then(function(response) {
                    // Logged in

                    // Not pretty, but we need this outside of Vue, namely in the login-modal.js
                    window.loggedIn = true;

					this.userData = response.body.data;
					this.$bus.$emit('user-login', this.userData);

					let userId = null;

					if ( this.userData.uid )
					{
						userId = this.userData.uid;
					}

					if ( typeof dataLayer === 'object' )
					{
						var data = {
							'event': 'userloaded',
							'userID': userId
						};
						if ( window.CLI && CLI.consent )
						{
							data.cookieConsent = CLI.consent.performance ? 'performance' : 'performance_blocked'
						}
						dataLayer.push(data);
					}
				}, function(response) {
                    // Not logged in

                    // Not pretty, but we need this outside of Vue, namely in the login-modal.js
                    window.loggedIn = false;

					if (response.body.code == "planned_maintenance")
					{
						this.userData = { planned_maintenance: true }
					}

					this.$bus.$emit('user-login', false);

					if ( typeof dataLayer === 'object' )
					{
						var data = {
							'event': 'userloaded',
							'userID': null
						};

						if ( window.CLI && CLI.consent )
						{
							data.cookieConsent = CLI.consent.performance ? 'performance' : 'performance_blocked'
						}
						dataLayer.push(data);
					}

					// If the theme is not aterforsaljare, check if the user is logged in
					if (this.$root.themeName != 'aterforsaljare') {
						// If the user is not logged in, check if the user is not on the sso base url,
						// and if the response from the request has no session
						if (! this.loggedIn
							&& window.location.hostname !== window.ssoBaseUrl.replace(/(^\w+:|^)\/\//, '')
							&& response.headers.get('X-Has-Session') === 'false'
						) {
							// Try to replicate the session from the sso base url
							const currentUrl = new URL(window.location.href);
							const url = new URL('/pressadmin-api/v1/auth-start/', currentUrl);
							url.searchParams.append('source_url', currentUrl.toString());

							window.ssoRedirectUrl = url.toString();
						} else {
                            window.ssoRedirectUrl = null;
                        }
					}
				});
			}
		},

		mounted: function() {
			this.checkLogin();
			this.themeName = this.$el.dataset.themename
		},

		created: function() {
			let _this = this;

			this.$bus.$on('cart:updated', function(value) {
				_this.userData.cart = value;
			});

			this.$bus.$on('user-login', function (success) {
				if ( ! success )
				{
					_this.$http.get(window.pressadminBaseUrl + '/user/test/', {
						credentials: true,
						withCredentials: true
					}).then(function (response) {
						this.$bus.$emit('can-login', response.body.data);
					}, function () {
						this.$bus.$emit('can-login', false);
					})
				}
			});
		}
	});
};